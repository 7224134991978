import api from '@/app/core/services/api.service';
import PaginatedUsersDto from '@/app/users/dto/paginated-users.dto';
import ApartmentDto, { ApartmentAutoMessagesDto } from '../dto/apartment.dto';
import ContractDto from '../dto/contract.dto';
import ListApartmentsDto from '../dto/list-apartments.dto';
import PaginatedApartmentsDto from '../dto/paginated-apartments.dto';

const listApartments = (params: ListApartmentsDto) => (
  api.get<PaginatedApartmentsDto>('/apartments', { params })
);

export const getApartments = () => (
  api.get<PaginatedApartmentsDto>('/apartments/all')
);

export const getApartmentPictures = (apartmentId: string) => (
  api.get<string[]>(`/apartments/${apartmentId}/pictures`)
);

export const downloadApartmentPicture = (apartmentId: string, picture: string) => (
  api.get<Blob>(`/apartments/${apartmentId}/pictures/${picture}`, {
    responseType: 'blob',
  })
);

export const removeApartmentPicture = (apartmentId: string, picture: string) => (
  api.delete(`/apartments/${apartmentId}/pictures/${picture}`)
);

export const uploadApartmentPictures = (apartmentId: string, pictures: File[]) => {
  const formData = new FormData();
  pictures.forEach((picture) => formData.append('pictures', picture));

  return api.post<string[]>(`/apartments/${apartmentId}/pictures`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const getApartmentOwners = (apartmentId: string) => (
  api.get<PaginatedUsersDto>(`/apartments/${apartmentId}/owners`)
);

export const getApartmentAutoMessages = (apartmentId: string) => (
  api.get<ApartmentAutoMessagesDto>(`/apartments/${apartmentId}/auto-messages`)
);

const getApartmentNoOwners = (apartmentId: string) => (
  api.get<PaginatedUsersDto>(`/apartments/${apartmentId}/no-owners`)
);

const getContract = (apartmentId: string, ownerId: string) => (
  api.get(`/apartments/${apartmentId}/contracts/${ownerId}`)
);

export const updateApartmentAutoMessages = (apartmentId: string, dto: ApartmentAutoMessagesDto) => (
  api.put(`/apartments/${apartmentId}/auto-messages`, dto)
);

const updateContract = (apartmentId: string, ownerId: string, contract: ContractDto) => (
  api.put(`/apartments/${apartmentId}/contracts/${ownerId}`, contract)
);

const addApartmentOwner = (apartmentId: string, ownerId: string) => (
  api.post(`/apartments/${apartmentId}/owners/${ownerId}`)
);

const removeApartmentOwner = (apartmentId: string, ownerId: string) => (
  api.delete(`/apartments/${apartmentId}/owners/${ownerId}`)
);

const getAgentApartments = (agentId: string) => (
  api.get<{ items: string[] }>(`/agents/${agentId}/apartments`)
);

const updateAgentApartments = (agentId: string, apartmentIds: string[]) => (
  api.put(`/agents/${agentId}/apartments`, { apartmentIds })
);

const getOwnerApartments = (ownerId: string) => (
  api.get<{ items: string[] }>(`/owners/${ownerId}/apartments`)
);

const updateOwnerApartments = (ownerId: string, apartmentIds: string[]) => (
  api.put(`/owners/${ownerId}/apartments`, { apartmentIds })
);

const createApartment = (dto: ApartmentDto) => (
  api.post<ApartmentDto>('/apartments', dto)
);

const getApartment = (id: string) => (
  api.get<ApartmentDto>(`/apartments/${id}`)
);

const updateApartment = (id: string, dto: ApartmentDto) => (
  api.put<ApartmentDto>(`/apartments/${id}`, dto)
);

const deleteApartment = (id: string) => (
  api.delete<ApartmentDto>(`/reservations/apartment/${id}`)
);

// const deleteApartment = (id: string) => (
//   api.delete<ApartmentDto>(`/apartments/${id}`)
// );

export default {
  getContract,
  updateContract,
  getApartment,
  listApartments,
  getApartments,
  createApartment,
  updateApartment,
  deleteApartment,
  addApartmentOwner,
  getApartmentOwners,
  getAgentApartments,
  getOwnerApartments,
  getApartmentPicture: downloadApartmentPicture,
  getApartmentNoOwners,
  removeApartmentOwner,
  updateAgentApartments,
  updateOwnerApartments,
  removeApartmentPicture,
};
