




































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { FormAction } from '@/app/core/types';
import { required } from '@/app/core/validation';
import FormMixin from '@/app/core/mixins/form.mixin';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ApartmentDto from '../dto/apartment.dto';

@Component
export default class ApartmentForm extends Mixins(FormMixin, HandlesErrorMixin) {
  @Prop({ type: String, required: true })
  action!: FormAction;

  @Prop({ type: Object, default: null })
  apartment!: ApartmentDto;

  private defaultData: ApartmentDto = {
    name: '',
    description: '',
    amenities: [],
    location: {
      address: '',
      city: '',
      country: '',
      zip: '',
      latitude: '',
      longitude: '',
    },
    rooms: {
      bedrooms: 0,
      bathrooms: 0,
      maxOccupancy: 1,
    },
    smoobu: {},
  };
  protected data: ApartmentDto = this.defaultData;

  protected rules = {
    name: [required],
    description: [],
    amenities: [],
    location: {
      address: [required],
      city: [required],
      country: [required],
      zip: [required],
      latitude: [required],
      longitude: [required],
    },
    rooms: {
      bedrooms: [required],
      bathrooms: [required],
      maxOccupancy: [required],
    },
  };

  @Watch('apartment', { immediate: true })
  setData() {
    this.data = this.apartment || this.defaultData;
  }
}
